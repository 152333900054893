<template>
    <div class="mt-8 mb-8 mx-auto">
        <label for="default-search"
               class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
        <div class="relative">
            <div aria-label="Loading..." role="status" class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" v-if="isDebouncing">
                <svg class="h-5 w-5 animate-spin" viewBox="0 0 24 24">
                    <path class="fill-black" d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                    <path class="fill-yellow" d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                </svg>
            </div>

            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" v-else>
                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                     stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
            </div>

            <input type="search" id="default-search"
                   v-model="searchQuery"
                   ref="search"
                   class="block border border-grey-50 p-3 pl-10 rounded w-full resize-none font-medium rounded-lg text-sm"
                   placeholder="Search bikes, notes, emails..."/>
        </div>

    </div>
</template>

<script>
import {debounce} from 'lodash';

export default {
    name: 'AssessmentSearch',
    data() {
        return {
            internalSearchQuery: null,
            isDebouncing: false
        };
    },
    mounted() {
        this.internalSearchQuery = new URLSearchParams(window.location.search).get('query');
        if(this.internalSearchQuery) {
            this.$refs.search.focus();
        }
    },
    methods: {
        redirect: debounce(function () {
            // https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set('query', this.internalSearchQuery);
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            window.location.replace(newRelativePathQuery);
            // history.pushState(null, '', newRelativePathQuery);
            this.isDebouncing = false;
        }, 1000)
    },
    computed: {
        searchQuery: {
            get() {
                return this.internalSearchQuery;
            },
            set(val) {
                this.isDebouncing = true;
                this.internalSearchQuery = val;
                this.redirect();
            }
        },
    }
};
</script>

<style scoped>

</style>
