<template>
    <div class="card col-12 mt-5">
        <div class="card-body row">
            <!-- Header (Brand/Model & Next Button) -->
            <div class="flex justify-between items-end mb-5">
                <div class="flex gap-5">
                    <div>
                        <h3>Brand &amp; Model</h3>
                        <span class="text-2xl font-light">{{ assessment?.brand_name }} {{ assessment?.model_name }}</span>
                    </div>

                </div>
                <div class="flex gap-4">
                    <button class="border border-danger uppercase py-2 px-8 rounded-3xl leading-none font-semibold" @click="deleteButtonClicked" v-if="assessment.deleted_at === null">
                        Delete
                    </button>
                    <button class="border border-blue uppercase py-2 px-8 rounded-3xl leading-none font-semibold" @click="restoreButtonClicked" v-else>
                        Restore
                    </button>
                    <button class="border border-blue uppercase py-2 px-8 rounded-3xl leading-none font-semibold" @click="skipButtonClicked">
                        {{ assessment.assessment ? 'Go to next Unassessed' : 'Skip to next' }} {{ assessmentFilterType }} Assessment
                    </button>
                    <button class="border border-blue uppercase py-2 px-8 rounded-3xl leading-none font-semibold" @click="indexButtonClicked">
                        Back to List
                    </button>
                </div>
            </div>
            <div v-if="assessment.assessment !== null" class="w-full my-8 bg-green bg-opacity-5 border-b-4 border-green rounded p-3">
                <span v-if="assessment.assessor">
                    <strong>{{ assessment.assessor.name }}</strong>
                </span>
                <span v-else>
                    Automatically
                </span>

                already assessed As:
                <strong>
                    <span v-if="assessment.assessment === true">Compatible</span>
                    <span v-if="assessment.assessment === false">Incompatible</span>
                </strong>

                with a<span v-if="assessment.kit_type === null">n</span>

                <strong>
                    <span v-if="assessment.kit_type === null"> Unknown</span>
                    <span v-if="assessment.kit_type === 'folding'"> Folding</span>
                    <span v-if="assessment.kit_type === 'universal'"> Universal</span>
                </strong>

                kit

                <br>
            </div>

            <div class="w-full mt-5 mb-5 rounded overflow-hidden border border-grey-50 border-b-0" v-if="assessment.deleted_at">
                <div class="font-light bg-danger bg-opacity-5 border-b-3 border-danger p-4">
                    This assessment has been deleted. Please restore it before assessing this bike.
                </div>
            </div>

            <!-- 99 Spokes Info -->
            <div>
                <template v-if="!assessment.id">
                    <span className="spinner-border spinner-border-sm"></span>
                </template>
                <template v-else-if="!assessment.spokes_id || spokesNotFound">
                    <div class="mb-2">
                        <strong>No 99 Spokes info {{ spokesNotFound ? 'found' : 'given' }} for this bike</strong>
                        <a class="ml-2 border border-blue uppercase py-1 px-2 rounded-3xl leading-none font-semibold text-sm" target="_blank" 
                           :href="'https://www.google.com/search?q=' + assessment?.brand_name + ' ' + assessment?.model_name">
                            Google '{{ assessment?.brand_name }} {{ assessment?.model_name }}'
                        </a>
                    </div>
                    <assessment-images :images="assessment.user_image_url ? [{src: assessment.user_image_url, title: 'Customer uploaded image', alt: 'Customer uploaded image'}] : []"></assessment-images>
                </template>
                <template v-else>
                    <ninety-nine-spokes-info 
                        :spokes-id="assessment.spokes_id" 
                        :user-image-url="assessment.user_image_url ? [assessment.user_image_url] : []"
                        @spokesNotFound="() => spokesNotFound = true"></ninety-nine-spokes-info>
                </template>
            </div>
            <!-- Modified Warning -->
            <div class="w-full mt-5 rounded overflow-hidden border border-grey-50 border-b-0">
                <div v-if="!assessment.id" class="p-5 mx-auto my-auto rounded"><span className="spinner-border spinner-border-sm"></span></div>
                <div v-else-if="assessment.non_standard" class="font-light bg-danger bg-opacity-5 border-b-3 border-danger p-4">The user has indicated that this bike has been modified</div>
                <div v-else-if="!assessment.non_standard" class="font-light bg-green bg-opacity-5 border-b-3 border-green p-4">The user has indicated that this bike is standard</div>
            </div>

            <div class="w-full mt-5 rounded overflow-hidden border border-grey-50 border-b-0">
                <div v-if="!assessment.id" class="p-5 mx-auto my-auto rounded"><span className="spinner-border spinner-border-sm"></span></div>
                <div v-else-if="assessment.email" class="font-light bg-info bg-opacity-5 border-b-3 border-info p-4">
                    <div class="flex flex-row justify-between">
                        <div>
                            <strong class="font-semibold">Requested by:</strong>
                            <span>{{assessment.email}}</span>
                        </div>
                        <div v-if="assessment.invalid_email === true">
                            <edit-email-address
                                :initial-email="assessment.email"
                                :assessment-request-id="assessment.id"
                            ></edit-email-address>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-8">
                <div class="relative" v-if="assessment.deleted_at === null">
                    <label class="text-sm bg-white px-2 absolute top-0 left-1 -translate-y-1/2">Internal Additional Notes</label>
                    <div class="col-sm-9">
                        <textarea
                            :disabled="assessment.assessment !== null"
                            class="border border-grey-50 p-3 rounded w-full resize-none h-[206px]" placeholder="Enter your internal non-public notes here" rows="4" v-model="assessment.assessment_notes" maxlength="255"></textarea>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- Buttons -->
    <div class="mt-5" v-if="assessment.deleted_at === null">
        <h2 class="font-semibold text-2xl mb-5">Assessment</h2>
        <div class="card-body row">
            <assessment-buttons
                :disabled="assessment.assessment !== null"
                :initial-public-notes="assessment?.public_notes"
                :bike-name="assessment.brand_name + ' ' + assessment.model_name"
                v-on:compatibilityMarked="clickCompatibilityMarked"
                :is-initially-compatible="assessment?.assessment"
                :initial-kit-type="assessment?.kit_type"
                :auto-assessment-exists="assessment?.auto_assessment_exists"
                :incompatibility-reasons="getIncompatibilityReasons"
                :all-allowed="assessment?.non_standard === false && assessment?.spokes_id !== null"
                :incompatibility-reason-other="assessment?.incompatibility_reason_other"></assessment-buttons>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';

import { AssessmentRequest } from './AssessmentRequest';

import NinetyNineSpokesInfo from './NinetyNineSpokesInfo.vue';
import AssessmentImages from './AssessmentImages.vue';
import AssessmentButtons from './AssessmentButtons.vue';
import { AssessmentIncompatibilityReasons } from './AssessmentIncompatibilityReasons';
import {KitType} from './KitType';
import EditEmailAddress from './EditEmailAddress.vue';

export default defineComponent(
    {
        components: {EditEmailAddress, AssessmentImages, NinetyNineSpokesInfo, AssessmentButtons },
        props: {
            assessmentRequestId: {
                required: true,
                type: Number
            },
            assessmentFilterType: {
                required: false,
                type: String,
            }
        },
        data() {
            return {
                assessment: {} as AssessmentRequest,
                spokesNotFound: false
            };
        },
        created() {
            this.fetchAssessment();
        },
        computed: {
            getIncompatibilityReasons() {
                if(this.assessment && this.assessment.assessment === null && (this.assessment.assessment_incompatibility_reasons ?? []).length === 0) {
                    return (Object.keys(AssessmentIncompatibilityReasons) as Array<keyof typeof AssessmentIncompatibilityReasons>)
                        .filter((key: keyof typeof AssessmentIncompatibilityReasons) => AssessmentIncompatibilityReasons[key] === AssessmentIncompatibilityReasons.thruAxle);
                }
                return this.assessment?.assessment_incompatibility_reasons ?? [];
            }
        },
        methods: {
            fetchAssessment() {
                axios.get('/api/wifmb/assessment/' + this.assessmentRequestId)
                    .then(({ data }) => {
                        this.assessment = data;
                    });
            },
            deleteButtonClicked() {
                axios.delete('/api/wifmb/assessment/' + this.assessmentRequestId)
                    .then(() => {
                        window.location.href = '/tools/wifmb/assessment-requests/' + this.assessmentRequestId;
                    });
            },
            restoreButtonClicked() {
                axios.patch('/api/wifmb/assessment/' + this.assessmentRequestId + '/restore')
                    .then(() => {
                        window.location.href = '/tools/wifmb/assessment-requests/' + this.assessmentRequestId;
                    });
            },
            skipButtonClicked() {
                let href = '/tools/wifmb/assessment-requests/' + this.assessmentRequestId + '/next';
                if (this.assessmentFilterType) {
                    href += '?type=' + this.assessmentFilterType;
                }
                window.location.href = href;
            },
            indexButtonClicked() {
                window.location.href = '/tools/wifmb/assessment-requests/';
            },
            clickCompatibilityMarked(all: boolean, incompatibilityTypes: Array<AssessmentIncompatibilityReasons>, otherIncompatibilityNotes: string | null, publicNotes: string | null, kitType: KitType | null) {
                axios.put('/api/wifmb/assessment/' + this.assessmentRequestId, {
                    assessment: incompatibilityTypes.length === 0 ? true : false,
                    assessment_notes: this.assessment.assessment_notes,
                    assessment_incompatibility_reasons: incompatibilityTypes,
                    incompatibility_reason_other: incompatibilityTypes.length === 0 ? null : otherIncompatibilityNotes,
                    apply_to_all_bikes: all,
                    public_notes: publicNotes,
                    kit_type: kitType
                })
                    .then(({ data }) => {
                        this.assessment = data;
                        this.skipButtonClicked();
                    });
            }
        }
    });
</script>
