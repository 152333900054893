<template>
    <div v-if="spokesData.id">
        <div class="rounded border border-grey-50 bg-grey-100 p-8 mb-5">
            <span class="mb-6 flex justify-between">
                <span><strong>Technical details</strong> <a :href="spokesData.url" class="underline" rel="noopener noreferrer" target="_blank">99 Spokes Site Link (New Tab)</a></span>
                <button class="ml-2 border border-blue uppercase py-1 px-2 rounded-3xl leading-none font-semibold text-sm"
                        @click="() => showAllTechnicalDetails = !showAllTechnicalDetails">{{ showAllTechnicalDetails ? "Hide " : "Show All " }} Technical Details</button>
            </span>
            <div class="w-full">
                <div class="grid lg:grid-cols-2 gap-5 font-light">
                    <span class="mb-4" v-if="showAllTechnicalDetails">
                        <h3 class="font-semibold mb-2 text-xl">Maker: </h3>{{ spokesData.maker }}
                    </span>
                    <span class="mb-4" v-if="showAllTechnicalDetails">
                        <h3 class="font-semibold mb-2 text-xl">Model: </h3>{{ spokesData.model }}
                    </span>
                    <span class="mb-4" v-if="showAllTechnicalDetails">
                        <h3 class="font-semibold mb-2 text-xl">Family: </h3>{{ spokesData.family }}
                    </span>
                    <span class="mb-4" v-if="showAllTechnicalDetails">
                        <h3 class="font-semibold mb-2 text-xl">Year: </h3>{{ spokesData.year }}
                    </span>
                    <span class="mb-4" v-if="showAllTechnicalDetails">
                        <h3 class="font-semibold mb-2 text-xl">Category: </h3>{{ spokesData.category }}
                    </span>
                    <span class="mb-4" v-if="showAllTechnicalDetails">
                        <h3 class="font-semibold mb-2 text-xl">Subcategory: </h3>{{ spokesData.subcategory }}
                    </span>
                    <div v-for="(index, value) in spokesData.components" :key="value" class="mb-4" :class="[defaultComponentKeys.includes(value) || showAllTechnicalDetails ? '' : 'hidden']">
                        <span>
                            <h3 class="font-semibold mb-2 text-xl">{{ value }}</h3>
                            {{ index?.description }}
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <assessment-images :images="images"></assessment-images>
    </div>
    <div v-else>
        <span className="spinner-border spinner-border-sm"></span>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import axios from 'axios';
import { NinetyNineSpokesData } from './NinetyNineSpokesData';
import AssessmentImages, {VueEasyLightboxImage} from './AssessmentImages.vue';

export default defineComponent(
    {
        components: {AssessmentImages},
        props: {
            spokesId: {
                required: true,
                type: String
            },
            userImageUrl: {
                required: false,
                type: Array as PropType<string[]>
            }
        },
        data() {
            return {
                spokesData: {} as NinetyNineSpokesData,
                defaultComponentKeys: ['frame', 'frontHub', 'fork', 'brakes'],
                showAllTechnicalDetails: false
            };
        },
        created() {
            this.fetchSpokesData();
        },
        computed: {
            images(): VueEasyLightboxImage[] {
                let images = [];
                for (let image of this.spokesData?.images ?? []) {
                    images.push({
                        src: image.url,
                        title: 'Image gallery',
                        alt: '99 Spoke Image'
                    });
                }
                for(let image of this.userImageUrl ?? []) {
                    images.push({
                        src: image,
                        title: 'Customer uploaded image',
                        alt: 'Customer uploaded image'
                    });
                }

                return images;
            }
        },
        emits: ['spokesNotFound'],
        methods: {
            fetchSpokesData() {
                axios.get('/api/wifmb/99_spokes_data/' + this.spokesId)
                    .then(({ data }) => {
                        this.spokesData = data;
                    }).catch((error) => {
                        if (error.response.status === 404) {
                            this.$emit('spokesNotFound');
                        }
                        else
                        {
                            throw error;
                        }
                    });
            },
        }
    });
</script>
