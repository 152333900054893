
export enum AssessmentIncompatibilityReasons {
    thruAxle = 'Thru-Axle',
    rollerOrDrumBrake = 'Roller/Drum Brakes',
    wideTyre = 'Wide Tyres (> 60 mm)',
    wideStem = 'Wide Stem',
    leftyHub = 'Lefty Hub',
    forkWidthBad = 'Fork Width Bad (not 100 nor 75 mm)',
    trike = 'Trike (2 front wheels)',
    ThruScrew = 'Thru Screw',
    eBike = 'e-Bike',
    moreInfoRequired = 'More Info Required (put in notes below)',
    other = 'Other (put in notes below)',
}
