// app.js
import { createApp } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
import * as Sentry from '@sentry/vue';
import AssessmentRequestCompatibilityReviewTool from './CompatibilityReviewTool/AssessmentRequestCompatibilityReviewTool.vue';
import AssessmentSearch from './CompatibilityReviewTool/AssessmentSearch.vue';

if(document.getElementById('wifmb_assessment_compatibility_review')) {
    const wifmbAssessmentCompatibilityReviewTool = createApp();

    wifmbAssessmentCompatibilityReviewTool.mixin(Sentry.createTracingMixins({ trackComponents: true }));
    Sentry.attachErrorHandler(wifmbAssessmentCompatibilityReviewTool);

    wifmbAssessmentCompatibilityReviewTool.use(VueEasyLightbox);
    wifmbAssessmentCompatibilityReviewTool.component('wifmb-assessment-compatibility-review-tool', AssessmentRequestCompatibilityReviewTool);
    wifmbAssessmentCompatibilityReviewTool.component('wifmb-assessment-search', AssessmentSearch);
    wifmbAssessmentCompatibilityReviewTool.mount('#wifmb_assessment_compatibility_review');
}
