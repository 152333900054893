<template>
    <div class="">
        <span class="text-xl block mb-2">This bike is&hellip;</span>

        <div class="mb-6">
            <label class="inline-block max-w-sm w-full">
                <input type="radio" name="compat" :value="true" class="peer sr-only" v-model="isCompatible" :disabled="disabled">
                <span
                    class="rounded border border-grey-50 px-4 py-5 gap-4 flex items-center cursor-pointer peer-checked:border-blue bg-green bg-opacity-5 group">
                    <span
                        class="rounded-full bg-white border border-grey-50 w-[30px] h-[30px] group-peer-checked:border-none">
                        <i class="fas fa-check-circle text-3xl leading-none invisible group-peer-checked:visible"></i>
                    </span>
                    <span class="font-light">
                        Compatible
                    </span>
                </span>
            </label>
        </div>

        <div class="mb-6">
            <label class="inline-block max-w-sm w-full">
                <input type="radio" name="compat" :value="false" class="peer sr-only" v-model="isCompatible" :disabled="disabled">
                <span
                    class="rounded border border-grey-50 px-4 py-5 gap-4 flex items-center cursor-pointer peer-checked:border-blue bg-danger bg-opacity-5 group">
                    <span
                        class="rounded-full bg-white border border-grey-50 w-[30px] h-[30px] group-peer-checked:border-none">
                        <i class="fas fa-check-circle text-3xl leading-none invisible group-peer-checked:visible"></i>
                    </span>
                    <span class="font-light">
                        Not Compatible
                    </span>
                </span>
            </label>
        </div>

        <!-- checkbox example -->

        <!--        <div class="mb-5">-->
        <!--            <button @click="markCompatibility(false)" class="border border-grey-50 text-left rounded mb-3 py-3 px-5 block w-1/4" :disabled="incompatibilityTypes.length > 0">-->
        <!--                Compatible-->
        <!--            </button>-->
        <!--            <button @click="markCompatibility(false)" class="border border-grey-50 text-left rounded py-3 px-5 block w-1/4" :disabled="incompatibilityTypes.length == 0">-->
        <!--                Incompatible-->
        <!--            </button>-->
        <!--        </div>-->

        <div class="" v-if="isCompatible === false">
            <span class="text-xl block mb-2">Incompatibility Reason</span>
            <p class="font-light mb-2">You can choose more than one if needed</p>

            <div class="flex flex-wrap items-center">
                <div v-for="value in incompatibilityPossibleTypes" :key="value" class="col-6 m-0 p-1">
                    <button class="border py-3 px-8 rounded-3xl leading-tight mb-3"
                            :class="[incompatibilityTypes.includes(value) ? 'border-blue text-blue bg-grey-110' : 'border-grey-50 text-grey-50']"
                            @click="setIncompatibilityReason(value)"
                            :disabled="disabled">
                        {{ value }}
                    </button>
                </div>
            </div>

            <div class="mt-8" v-if="hasSelectedOtherReason || hasSelectedMoreInfoReason || (otherIncompatibilityNotes !== '' && otherIncompatibilityNotes !== null)">
                <div class="relative">
                    <label class="text-sm bg-white px-2 absolute top-0 left-1 -translate-y-1/2">Incompatibility notes (may be sent to user) {{ hasSelectedOtherReason || hasSelectedMoreInfoReason  ? '(*)' : '' }}</label>
                    <div class="flex flex-wrap justify-between pt-4">
                        <div class="flex flex-wrap grow">
                            <button v-if="hasSelectedMoreInfoReason" class="border py-3 px-8 mx-1 rounded-3xl leading-tight mb-3"
                                    @click="otherIncompatibilityNotes = ('Could not find brand/model')"
                                    :disabled="disabled">
                                Could not find brand/model
                            </button>
                            <button v-if="hasSelectedMoreInfoReason" class="border py-3 px-8 mx-1 rounded-3xl leading-tight mb-3"
                                    @click="otherIncompatibilityNotes = ('No model provided')"
                                    :disabled="disabled">
                                No model provided
                            </button>
                            <button v-if="hasSelectedMoreInfoReason" class="border py-3 px-8 mx-1 rounded-3xl leading-tight mb-3"
                                    @click="otherIncompatibilityNotes = ('Model not specific enough')"
                                    :disabled="disabled">
                                Model not specific enough
                            </button>
                        </div>
                        <button class="border py-1 px-6 text-sm mx-1 rounded-3xl leading-tight mb-3 italic"
                                :class="{'-mt-8': !hasSelectedMoreInfoReason}"
                                @click="otherIncompatibilityNotes = ''"
                                :disabled="disabled">
                            Clear
                        </button>
                    </div>
                    <div class="col-sm-9">
                        <textarea
                            :disabled="disabled"
                            :required="hasSelectedOtherReason || hasSelectedMoreInfoReason"
                            class="border border-grey-50 p-3 rounded w-full resize-none h-[206px]"
                            placeholder="Enter the reason the bike is incompatible"
                            rows="4"
                            v-model="otherIncompatibilityNotes"
                            maxlength="255"></textarea>
                    </div>
                </div>
            </div>
        </div>



        <div :class="{ 'hidden': isCompatible === false}">
            <span class="text-xl block mb-2">The kit type should be...&hellip;</span>

            <div class="mb-6">
                <label class="inline-block max-w-sm w-full">
                    <input type="radio" name="kittype" value="universal" class="peer sr-only" v-model="kitType" :disabled="disabled">
                    <span
                        class="rounded border border-grey-50 px-4 py-5 gap-4 flex items-center cursor-pointer peer-checked:border-blue bg-opacity-5 group peer-disabled:cursor-not-allowed peer-checked:bg-grey-110">
                        <span
                            class="rounded-full bg-white border border-grey-50 w-[30px] h-[30px] group-peer-checked:border-none">
                            <i class="fas fa-check-circle text-3xl leading-none invisible group-peer-checked:visible"></i>
                        </span>
                        <span class="font-light">
                            <strong class="font-semibold">Universal</strong> kit
                            <span class="text-xs block">For bikes with a fork width of 100mm</span>
                        </span>
                    </span>
                </label>
            </div>

            <div class="mb-6">
                <label class="inline-block max-w-sm w-full">
                    <input type="radio" name="kittype" value="folding" class="peer sr-only" v-model="kitType" :disabled="disabled">
                    <span
                        class="rounded border border-grey-50 px-4 py-5 gap-4 flex items-center cursor-pointer peer-checked:border-blue bg-opacity-5 group peer-disabled:cursor-not-allowed peer-checked:bg-grey-110">
                        <span
                            class="rounded-full bg-white border border-grey-50 w-[30px] h-[30px] group-peer-checked:border-none">
                            <i class="fas fa-check-circle text-3xl leading-none invisible group-peer-checked:visible"></i>
                        </span>
                        <span class="font-light">
                            <strong class="font-semibold">Folding</strong> kit
                            <span class="text-xs block">For bikes with a fork width of 75-80mm</span>
                        </span>
                    </span>
                </label>
            </div>

            <div class="mb-6">
                <label class="inline-block max-w-sm w-full">
                    <input type="radio" name="kittype" :value="null" class="peer sr-only" v-model="kitType" :disabled="disabled">
                    <span
                        class="rounded border border-grey-50 px-4 py-5 gap-4 flex items-center cursor-pointer peer-checked:border-blue bg-opacity-5 group peer-disabled:cursor-not-allowed peer-checked:bg-grey-110">
                        <span
                            class="rounded-full bg-white border border-grey-50 w-[30px] h-[30px] group-peer-checked:border-none">
                            <i class="fas fa-check-circle text-3xl leading-none invisible group-peer-checked:visible"></i>
                        </span>
                        <span class="font-light">
                            Unknown
                            <span class="text-xs block">We won't recommend a specific kit type</span>
                        </span>
                    </span>
                </label>
            </div>
        </div>



        <span class="text-xl block mb-2">Applies to&hellip;</span>

        <div class="mb-6">
            <label class="inline-block max-w-sm w-full">
                <input type="radio" name="appliesto" :value="false" class="peer sr-only" v-model="assessAllBikes" :disabled="disabled">
                <span
                    class="rounded border border-grey-50 px-4 py-5 gap-4 flex items-center cursor-pointer peer-checked:border-blue bg-opacity-5 group peer-disabled:cursor-not-allowed peer-checked:bg-grey-110">
                    <span
                        class="rounded-full bg-white border border-grey-50 w-[30px] h-[30px] group-peer-checked:border-none">
                        <i class="fas fa-check-circle text-3xl leading-none invisible group-peer-checked:visible"></i>
                    </span>
                    <span class="font-light">
                        Just this <strong class="font-semibold">{{ bikeName }}</strong> bike
                        <span class="text-xs block">Has no impact on future submissions of the same bike</span>
                    </span>
                </span>
            </label>
        </div>

        <div class="mb-6">
            <label class="inline-block max-w-sm w-full">
                <input type="radio" name="appliesto" :value="true" class="peer sr-only" v-model="assessAllBikes"
                       :disabled="!allAllowed || disabled">
                <span
                    class="rounded border border-grey-50 px-4 py-5 gap-4 flex items-center cursor-pointer peer-checked:border-blue bg-opacity-5 group peer-disabled:cursor-not-allowed peer-checked:bg-grey-110 peer-disabled:text-gray-400">
                    <span
                        class="rounded-full bg-white border border-grey-50 w-[30px] h-[30px] group-peer-checked:border-none">
                        <i class="fas fa-check-circle text-3xl leading-none invisible group-peer-checked:visible"></i>
                    </span>
                    <span class="font-light">
                        All <strong class="font-semibold">{{ bikeName }}</strong> bikes
                        <span class="text-xs block">All future submissions of this bike will automatically receive the same assessment</span>
                    </span>
                </span>
            </label>
        </div>

        <div class="mt-8 mb-8">
            <div class="relative">
                <label class="text-sm bg-white px-2 absolute top-0 left-1 -translate-y-1/2">Personalised message (optional, sent to user)</label>
                <div class="col-sm-9">
                    <input
                        type="text"
                        class="border border-grey-50 p-3 rounded w-full resize-none"
                        placeholder="e.g. Nice bike colour!"
                        v-model="publicNotes"
                        :disabled="disabled"
                        maxlength="255" />
                </div>
            </div>
        </div>

        <div class="mb-20" v-if="isInitiallyCompatible === null">
            <button type="button"
                    class="bg-yellow rounded-3xl py-3 px-6 block uppercase font-semibold max-w-sm w-full"
                    :class="{'text-yellow-400': submitButtonDisabled}"
                    :disabled="submitButtonDisabled"
                    @click="markCompatibility">Submit assessment
            </button>

            <div class="w-full mt-5 rounded overflow-hidden border border-grey-50 border-b-0" v-if="submitButtonDisabled">
                <div class="font-light bg-danger bg-opacity-5 border-b-3 border-danger p-4">
                    {{submitButtonDisabledBecause}}
                </div>
            </div>


        </div>
    </div>

</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {AssessmentIncompatibilityReasons} from './AssessmentIncompatibilityReasons';
import {KitType} from './KitType';

export default defineComponent(
    {
        props: {
            disabled: {
                required: false,
                default: false,
                type: Boolean
            },
            allAllowed: {
                required: false,
                default: true,
                type: Boolean
            },
            bikeName: {
                required: true,
                type: String
            },
            incompatibilityReasons: {
                required: false,
                type: Array < string >,
                default: []
            },
            initialPublicNotes: {
                required: false,
                type: String,
                default: null
            },
            isInitiallyCompatible: {
                required: false,
                type: Boolean,
                default: null
            },
            incompatibilityReasonOther: {
                required: false,
                type: String,
                default: null
            },
            initialKitType: {
                required: false,
                type: String,
                default: null
            },
            autoAssessmentExists: {
                required: false,
                type: Boolean,
                default: null
            }
        },
        data() {
            return {
                incompatibilityTypes: [] as Array<AssessmentIncompatibilityReasons>,
                isCompatible: null as boolean | null,
                assessAllBikes: false as boolean,
                otherIncompatibilityNotes: '' as string | null,
                publicNotes: null as string | null,
                kitType: null as KitType | null
            };
        },
        mounted() {
            if(this.isInitiallyCompatible !== null && this.isInitiallyCompatible !== undefined) {
                this.assessAllBikes = this.autoAssessmentExists ;
            }
            this.isCompatible = this.isInitiallyCompatible;
            this.otherIncompatibilityNotes = this.incompatibilityReasonOther;
            this.incompatibilityReasons?.forEach((element: keyof typeof AssessmentIncompatibilityReasons) => {
                this.incompatibilityTypes.push(AssessmentIncompatibilityReasons[element]);
            });
            this.kitType = this.initialKitType as KitType | null;
        },
        watch: {
            initialKitType: function (newValue) {
                this.kitType = newValue;
            },
            initialPublicNotes: function (newValue) {
                this.publicNotes = newValue;
            },
            incompatibilityReasonOther: function (newValue) {
                this.otherIncompatibilityNotes = newValue;
            },
            isInitiallyCompatible: function (newValue) {
                this.isCompatible = newValue;
            },
            incompatibilityReasons: function (newValue) {
                newValue?.forEach((element: keyof typeof AssessmentIncompatibilityReasons) => {
                    this.incompatibilityTypes.push(AssessmentIncompatibilityReasons[element]);
                });
            }
        },
        computed: {
            incompatibilityPossibleTypes() {
                return AssessmentIncompatibilityReasons;
            },
            submitButtonDisabledBecause(): string | null {
                // If the user hasn't assessed the bike yet
                if (this.isCompatible === null) {
                    return 'Please assess the bike and mark it as compatible or incompatible';
                }
                // If the user has assessed the bike but not given any reasons for it
                if (this.isCompatible === false && this.incompatibilityTypes.length === 0) {
                    return 'Please give a reason why the bike is incompatible';
                }
                if ((this.hasSelectedOtherReason || this.hasSelectedMoreInfoReason) && !this.otherIncompatibilityNotes) {
                    return 'Add in the notes why the bike is incompatible';
                }
                return null;
            },
            submitButtonDisabled() {
                return this.submitButtonDisabledBecause !== null;
            },
            hasSelectedOtherReason() {
                return this.incompatibilityTypes.includes(AssessmentIncompatibilityReasons.other);
            },
            hasSelectedMoreInfoReason() {
                return this.incompatibilityTypes.includes(AssessmentIncompatibilityReasons.moreInfoRequired);
            }
        },
        methods: {
            setIncompatibilityReason(key: AssessmentIncompatibilityReasons) {
                if (this.incompatibilityTypes.includes(key)) {
                    //remove key from this.incompatibilityTypes
                    this.incompatibilityTypes = this.incompatibilityTypes.filter((value) => value != key);
                } else {
                    this.incompatibilityTypes.push(key);
                }
            },
            markCompatibility() {
                if (!this.submitButtonDisabled) {
                    let codedTypes = [] as Array<string>;
                    if (this.isCompatible === false) {
                        this.incompatibilityTypes.forEach(element => {
                            let a = Object.keys(AssessmentIncompatibilityReasons).find(
                                key => AssessmentIncompatibilityReasons[key] === element
                            );
                            if (a) {
                                codedTypes.push(a);
                            }
                        });
                    }
                    this.$emit('compatibilityMarked', this.assessAllBikes, codedTypes, this.otherIncompatibilityNotes, this.publicNotes, this.kitType);
                }
            }
        }
    });
</script>
