<template>
    <v-modal header="Edit email address">
        <template #activator="{show}">
            <div class="flex items-center">
                <button
                    @click="show"
                    class="hover:bg-gray-50 focus:ring focus:outline-none rounded-full"
                >
                    <i class="fa fa-pencil" />
                </button>
            </div>
        </template>
        <div class="mt-8 mb-8 mx-2">
            <div class="relative">
                <label class="text-sm bg-white px-2 absolute top-0 left-1 -translate-y-1/2">Email Address</label>
                <div class="col-sm-9">
                    <input
                        type="text"
                        class="border border-grey-50 p-3 rounded w-full resize-none"
                        placeholder="Type in the valid email of the requester."
                        v-model="email"
                        maxlength="255" />
                </div>
            </div>
        </div>
        <div class="mt-8 mb-8">
            <div class="flex flex-row justify-between">
                <div></div>
                <button type="button"
                        class="bg-yellow rounded-3xl py-3 px-6 block uppercase font-semibold max-w-sm w-full"
                        @click="updateEmail"
                        :disabled="email === null || email === ''">
                    Update email
                </button>
            </div>
        </div>
    </v-modal>
</template>

<script>
import VModal from '../../../../watson/resources/js/src/components/Atoms/VModal.vue';
import axios from 'axios';
export default {
    name: 'EditEmailAddress',
    components: {VModal},
    props: {
        initialEmail: {
            required: true,
            type: String
        },
        assessmentRequestId: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            email: null
        };
    },
    mounted() {
        this.email = this.initialEmail;
    },
    methods: {
        updateEmail() {
            axios.put('/api/wifmb/assessment/' + this.assessmentRequestId, {
                invalid_email: null,
                email: this.email
            })
                .then(() => {
                    window.location.reload();
                });
        }
    }
};
</script>

<style scoped>

</style>
