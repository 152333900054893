<template>
    <vue-easy-lightbox
        :imgs="images"
        :index="viewingImageIndex"
        :visible="viewingImages"
        @hide="viewingImages = false"
    ></vue-easy-lightbox>
    <div class="flex gap-5">
        <div
            v-for="(image, index) in images"
            :key="image.src"
            class="items-center rounded border border-grey-50 bg-grey-100 p-8 w-1/2 text-center hidden first-of-type:flex last-of-type:flex">
            <figure>
                <img :alt="image.alt" :src="image.src" class="img-fluid img-thumbnail cursor-pointer"
                     @click="viewImage(index)"/>
                <figcaption class="pt-3">{{ image.title }}</figcaption>
            </figure>
        </div>

    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';

export interface VueEasyLightboxImage {
  src: string,
  title: string,
  alt: string
}

export default defineComponent({
    components: {
        VueEasyLightbox
    },
    props: {
        images: {
            required: false,
            default: () => [],
            type: Array<VueEasyLightboxImage>
        }
    },
    data() {
        return {
            viewingImageIndex: null as number | null,
            viewingImages: false as boolean
        };
    },
    methods: {
        viewImage(imageIndex: number) {
            this.viewingImageIndex = imageIndex;
            this.viewingImages = true;
        },
    }
});
</script>

<style scoped>

</style>
